import React from "react";
import "./KeyEventsCalendarRow.scss";
import { Columns, RowItem } from "../calendarTypes";
import { Text } from "common-components";
import KeyEventsCalendarRowBorder from "./border/KeyEventsCalendarRowBorder";
import KeyEventsCalendarRowMilestoneFixed from "./milestone/fixed/KeyEventsCalendarRowMilestoneFixed";
import KeyEventsCalendarRowMilestoneRange from "./milestone/range/KeyEventsCalendarRowMilestoneRange";
import classNames from "classnames";
import KeyEventsCalendarRowDateline from "./dateline/KeyEventsCalendarRowDateline";
import KeyEventsCalendarRowMenu from "./menu/KeyEventsCalendarRowMenu";
import DragIcon from "icons/drag-icon";
import { Draggable } from "react-beautiful-dnd";
import { useCalendarModeHelper } from "global/use-key-events-calendar-mode-helper";

export const namespace = "rts-pa-key-events-calendar-row";

type Props = {
  columns: Columns;
  item: RowItem;
  index: number;
  isDraggable: boolean;
};

export default function KeyEventsCalendarRow(props: Props): JSX.Element {
  const calendarMode = useCalendarModeHelper();

  const rightContanerClassName = classNames(`${namespace}-right-container`, {
    odd: props.index % 2 === 1
  });

  return (
    <Draggable
      draggableId={props.item.id.toString()}
      isDragDisabled={!calendarMode.isRearrangeMode}
      index={props.index}
    >
      {provided => {
        return (
          <div
            className={namespace}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div className={`${namespace}-left-container`}>
              <Text>{props.item.name}</Text>

              {props.isDraggable ? (
                <div
                  className={`${namespace}-drag`}
                  {...provided.dragHandleProps}
                >
                  <DragIcon height={10} />
                </div>
              ) : (
                <KeyEventsCalendarRowMenu
                  rowId={props.item.id}
                  linkToSource={props.item.linkToSource}
                  links={props.item.links}
                />
              )}
            </div>
            <div className={rightContanerClassName}>
              <div
                className={`${namespace}-right-container-fixed`}
                style={{
                  gridTemplateColumns: props.columns.gridTemplateColumns
                }}
              >
                {props.item.fixedMilestones.map(m => (
                  <KeyEventsCalendarRowMilestoneFixed
                    key={m.id}
                    milestone={m}
                  />
                ))}
                {props.columns.years.map((y, i) =>
                  props.columns.years.length - 1 !== i ? (
                    <KeyEventsCalendarRowBorder key={y.year} year={y.year} />
                  ) : null
                )}
                <KeyEventsCalendarRowDateline columns={props.columns} />
              </div>
              <div
                className={`${namespace}-right-container-range`}
                style={{
                  gridTemplateColumns: props.columns.gridTemplateColumns,
                  display: !props.item.rangeMilestones.length
                    ? "none"
                    : "inherit"
                }}
              >
                {props.item.rangeMilestones.map(m => (
                  <KeyEventsCalendarRowMilestoneRange
                    key={m.id}
                    milestone={m}
                  />
                ))}
                {props.columns.years.map((y, i) =>
                  props.columns.years.length - 1 !== i ? (
                    <KeyEventsCalendarRowBorder key={y.year} year={y.year} />
                  ) : null
                )}
                <KeyEventsCalendarRowDateline columns={props.columns} />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}
