import React, { useContext, createRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Container } from "@gitlab-rtsensing/component-library";
import AgendaGrid from "components/agenda-grid/AgendaGrid";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import PrintHeader from "common-components/print-header/PrintHeader";
import PrintFooter from "common-components/print-footer/PrintFooter";
import "./Home.scss";
import StandaloneModal from "components/standalone-modal/StandaloneModal";
import PipelineModal from "components/pipeline-modal/PipelineModal";
import ExportModal from "components/export-modal/ExportModal";
import classNames from "classnames";
import { ExportMode } from "components/export-modal/export-modal-options/ExportModalOptions";
import { GlobalConfigModal } from "components/global-config-modal/GlobalConfigModal";
import { UpdateHomepageImageModal } from "components/update-homepage-image-modal/UpdateHomepageImageModal";
import EsgModal from "components/esg-modal/EsgModal";
import BiosimilarsModal from "components/biosimilars-modal/BiosimilarsModal";
import AgendaItemBuilderModal from "components/agenda-item-builder-modal/AgendaItemBuilderModal";
import { useSearchParams } from "react-router-dom";
import UpsertAgendaModal from "components/upsert-agenda-modal/UpsertAgendaModal";
import CommercialModal from "components/commercial-modal/CommercialModal";
import TalentDinbModal from "components/talent-dinb-modal/TalentDinbModal";
import UpsertAgendaItemModal from "components/upsert-agenda-item-modal/UpsertAgendaItemModal";
import ImportAgendaItemModal from "components/import-agenda-item-modal/ImportAgendaItemModal";

const namespace = "rts-pa-agenda-grid-page";

export default function AgendaGridPage(): JSX.Element {
  const isLoaded = useContext(PermissionsContext).isLoaded;
  const perms = useContext(PermissionsContext).grid;
  const ref = createRef<HTMLDivElement>();
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [exportMode, setExportMode] = useState<ExportMode | undefined>();

  const [searchParams] = useSearchParams();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onAfterPrint: () => {
      setExportMode(undefined);
    }
  });

  useEffect(() => {
    if (exportMode) {
      handlePrint();
    }
  }, [exportMode, handlePrint]);

  const onExportModalClick = () => {
    if (!perms.status || searchParams.get("limited_view") === "true") {
      setExportMode("export without status");
    } else {
      setExportModalOpen(true);
    }
  };

  const onExportOption = (option: ExportMode) => {
    setExportMode(option);
    setExportModalOpen(false);
  };

  const printContainerClasses = classNames(namespace, {
    "export-no-status": exportMode === "export without status"
  });

  return (
    <>
      <div className={printContainerClasses} ref={ref}>
        <PermissionsWrapper permissions={isLoaded}>
          <table>
            <thead>
              <tr>
                <td>
                  <div className="rts-pa-header-space"></div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Container>
                    <PermissionsWrapper permissions={perms.view}>
                      <AgendaGrid onExportClick={onExportModalClick} />
                    </PermissionsWrapper>
                  </Container>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="rts-pa-footer-space"></div>
                </td>
              </tr>
            </tfoot>
          </table>
          <PrintHeader />
          <PrintFooter mode={exportMode} />
        </PermissionsWrapper>
      </div>
      {/* JCG: while using isMultitenancy flag, need to delay loading modals until permissions loaded,
       * since that delays loading JSX until RUNTIME_CONFIG and therefore isMultitenancy is loaded,
       * which is needed otherwise there's a race condition where we might load+cache
       * non-multitenancy data while in multitenancy-mode e.g. ["agenda-items"] in UpsertAgendaItemModal */}
      <PermissionsWrapper permissions={isLoaded}>
        <>
          <UpsertAgendaItemModal />
          <ImportAgendaItemModal />
          <StandaloneModal />
          <CommercialModal />
          <PipelineModal />
          <TalentDinbModal />
          <EsgModal />
          <BiosimilarsModal />
          <UpsertAgendaModal />
          <GlobalConfigModal />
          <UpdateHomepageImageModal />
          <AgendaItemBuilderModal />
          <ExportModal
            isOpen={exportModalOpen}
            onClose={() => setExportModalOpen(false)}
            onExport={onExportOption}
          />
        </>
      </PermissionsWrapper>
    </>
  );
}
