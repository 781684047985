import React, { useMemo } from "react";
import "./LineChart.scss";
import {
  chartColors,
  LineChart as LineChartDS,
  LineChartProps
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";
import { LineChartDataKey } from "api/common/line-chart";

const namespace = "rts-pa-line-chart";

type Props = Omit<LineChartProps, "dataKeys"> & {
  dataKeys: LineChartDataKey[];
};

export const LineChart = (props: Props) => {
  const className = classNames(namespace, props.className);

  // converts API-needed `null` values to ChartLib-needed `undefined` values
  const dataKeys = useMemo(() => {
    // obtained from brand -- might need to ask CompLib team to implement these themselves, or BrandApi to send actual hex values
    const colorMap = new Map<string, string>([
      ["primary1", chartColors.colorDataPrimary1 || ""],
      ["primary2", "#6FB8E8"],
      ["primary3", chartColors.colorDataPrimary2 || ""],
      ["primary4", "#FDB81E"]
    ]);

    return props.dataKeys.map(dk => ({
      lineKey: dk.lineKey,
      color: colorMap.get(dk.color || "") || dk.color || undefined,
      displayName: dk.displayName || undefined,
      dot: dk.dot || false,
      dash: dk.dash || false,
      hideInLegend: dk.hideInLegend || false
    }));
  }, [props.dataKeys]);

  return <LineChartDS {...props} className={className} dataKeys={dataKeys} />;
};

export default LineChart;
