import { Array, Boolean, Number, Record, Static, String } from "runtypes";
import {
  LinkValidator,
  StatusValidator,
  InactiveSectionValidator,
  AdvancedTooltipValidator
} from "../common";
import { CommercialCommonSalesDemandLiteSectionValidator } from "./commercial-common";
import { LineChartValidator } from "api/common/line-chart";

const CommercialBrandInactiveSectionValidator = InactiveSectionValidator.And(
  Record({
    tooltip: AdvancedTooltipValidator.nullable().optional()
  })
);

const CommercialBrandSalesDemandSectionTableRowItemValidator = Record({
  label: String,
  value: String,
  status: StatusValidator.nullable()
});

const CommercialBrandSalesDemandSectionTableRowValidator = Record({
  items: Array(CommercialBrandSalesDemandSectionTableRowItemValidator)
});

const CommercialBrandSalesDemandSectionTableValidator = Record({
  title: String,
  rows: Array(CommercialBrandSalesDemandSectionTableRowValidator)
});

const BrandSalesDemandSectionValidator = Record({
  title: String,
  status: StatusValidator,
  statusDriver: Boolean,
  tooltip: AdvancedTooltipValidator.nullable(),
  chartTitle: String.nullable(),
  chart: Array(
    Record({
      label: String,
      value: Number.nullable(),
      forecast: Number.nullable()
    })
  ),
  lineChart: LineChartValidator,
  tables: Array(CommercialBrandSalesDemandSectionTableValidator),
  links: Array(LinkValidator)
});

export const CommercialBrandSectionValidator = Record({
  salesDemandLiteSections: Array(
    CommercialCommonSalesDemandLiteSectionValidator.Or(
      CommercialBrandInactiveSectionValidator
    )
  ),
  salesDemandSections: Array(
    BrandSalesDemandSectionValidator.Or(CommercialBrandInactiveSectionValidator)
  )
});

export type CommercialBrandInactiveSection = Static<
  typeof CommercialBrandInactiveSectionValidator
>;
export type CommercialBrandSalesDemandSectionTable = Static<
  typeof CommercialBrandSalesDemandSectionTableValidator
>;
export type CommercialBrandSalesDemandSection = Static<
  typeof BrandSalesDemandSectionValidator
>;

export type CommercialBrandSection = Static<
  typeof CommercialBrandSectionValidator
>;
