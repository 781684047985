import React from "react";
import "./KeyEventsCalendarRowDateline.scss";
import dayjs from "dayjs";
import { Columns } from "components/key-events-calendar/calendarTypes";

export const namespace = "rts-pa-key-events-calendar-row-dateline";

type Props = {
  columns: Columns;
};

const lineCalc = (() => {
  const today = dayjs();

  //get column name
  const year = today.year();
  const month = today.month() + 1;
  const quarter = Math.ceil(month / 3);
  const columnName = `y${year}m${month}`;

  //get month progress
  const monStart = today.date(1);
  const monEnd = monStart.add(1, "month").subtract(1, "day");
  const totalDays = monEnd.diff(monStart, "day");
  const progressDays = today.diff(monStart, "day");
  const progressPct = (progressDays / totalDays) * 100;
  const monthProgress = `${progressPct}%`;

  return { year, quarter, columnName, monthProgress };
})();

export default function KeyEventsCalendarRowDateline(
  props: Props
): JSX.Element {
  // check if current year & quarter is within shown filtered dates
  if (
    !props.columns.years
      .find(y => y.year === lineCalc.year)
      ?.quarters.find(q => q.num === lineCalc.quarter)
  ) {
    return <></>;
  }

  return (
    <div
      className={namespace}
      style={{
        gridColumn: `${lineCalc.columnName} / span 1`
      }}
    >
      <div
        className={`${namespace}-container`}
        style={{ paddingLeft: lineCalc.monthProgress }}
      />
    </div>
  );
}
