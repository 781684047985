import React, { useEffect, useMemo, useState } from "react";
import "./KeyEventsCalendar.scss";
import KeyEventsCalendarHeader from "./header/KeyEventsCalendarHeader";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCalendarBoard, putCalendarBoard } from "api/calendar-board";
import {
  CalendarFilters,
  Columns,
  MilestoneFixed,
  MilestoneRange,
  Quarter,
  RowItem,
  Year
} from "./calendarTypes";
import KeyEventsCalendarRow from "./row/KeyEventsCalendarRow";
import useLimitedView from "global/use-limited-view";
import pages from "pages/pages";
import KeyEventsCalendarEmptyContainer from "./empty-container/KeyEventsCalendarEmptyContainer";
import { CalendarMilestone } from "api";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from "react-beautiful-dnd";
import { useCalendarModeHelper } from "global/use-key-events-calendar-mode-helper";
import produce from "immer";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { useCalendarContext } from "pages/key-events/CalendarContext";

export const namespace = "rts-pa-key-events-calendar";

type Props = {
  filters: CalendarFilters;
  setOnReorderSave: (fn: () => () => void) => void;
};

export default function KeyEventsCalendar(props: Props): JSX.Element {
  const { calendarRows, setCalendarRows } = useCalendarContext();
  const [snapshotRows, setSnapshotRows] = useState<RowItem[] | undefined>();
  const navigate = useNavigate();
  const calendarMode = useCalendarModeHelper();
  const { isLimitedView } = useLimitedView(pages.keyEvents);
  const { data, refetch } = useQuery(
    [
      "calendar-board",
      isLimitedView,
      props.filters.startDateMin,
      props.filters.startDateMax
    ],
    () =>
      getCalendarBoard(
        isLimitedView,
        props.filters.startDateMin,
        props.filters.startDateMax
      )
  );
  const { mutate: updateCalendarRow } = useMutation(putCalendarBoard, {
    onSuccess: () => refetch()
  });

  useEffect(() => {
    if (!props.setOnReorderSave) {
      return;
    }

    const onReorderSave = () => {
      const updatedRowOrder = produce(calendarRows, draftState => {
        for (let i = 0; i < draftState.length; i++) {
          const row = draftState[i];
          row.order = i + 1;
        }
      });

      const newRows = [];
      for (const row of updatedRowOrder) {
        newRows.push({ id: row.id, order: row.order });
      }

      updateCalendarRow(newRows);
      setSnapshotRows(undefined);

      navigate(pages.keyEvents.go());
    };

    props.setOnReorderSave(() => onReorderSave);
  }, [calendarRows]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDragEnd: OnDragEndResponder = result => {
    if (!result.destination) {
      return;
    }
    /* case: row is dragged into the same index */
    if (result.source.index === result.destination.index) {
      return;
    }

    const srcIndex = result.source.index;
    const destIndex = result.destination.index;

    const newRows = produce(calendarRows, draftState => {
      const removedRows = draftState.splice(srcIndex, 1);
      draftState.splice(destIndex, 0, removedRows[0]);
    });

    setCalendarRows(newRows);
  };

  useEffect(() => {
    //set temp data when entering edit
    if (calendarMode.isRearrangeMode) {
      setSnapshotRows(calendarRows);
    } else if (snapshotRows) {
      setCalendarRows(snapshotRows); //snapshot exists when canceling out
      setSnapshotRows(undefined);
    }
  }, [calendarMode.mode]); //eslint-disable-line react-hooks/exhaustive-deps

  const columns: Columns = useMemo((): Columns => {
    if (!data) {
      return {
        years: [],
        gridTemplateColumns: ""
      };
    }

    const years: Year[] = [];
    let gridTemplateColumns = "";
    for (const yearRange of data.range) {
      const year: Year = {
        year: yearRange.year,
        quarters: []
      };

      years.push(year);

      for (const quarterRange of yearRange.quarters) {
        const quarter: Quarter = {
          num: quarterRange,
          months: []
        };

        year.quarters.push(quarter);

        for (let i = 1; i <= 3; i++) {
          const month = i + (quarter.num - 1) * 3;
          quarter.months.push(month);
          gridTemplateColumns += ` [y${year.year}m${month}] minmax(25px, 1fr)`;
        }
      }
    }

    return {
      years: years,
      gridTemplateColumns: gridTemplateColumns
    };
  }, [data]);

  useEffect(() => {
    if (!data || !columns) {
      setCalendarRows([]);
    }

    const rows: RowItem[] = [];
    if (data?.rows) {
      for (const apiRow of data.rows) {
        // add row item
        const rowItem: RowItem = {
          id: apiRow.id,
          name: apiRow.name,
          linkToSource: apiRow.linkToSource,
          links: apiRow.links,
          order: apiRow.order,
          fixedMilestones: [],
          rangeMilestones: []
        };

        rows.push(rowItem);

        // create milestone mappers

        const mapToFixedMilestone = (
          apiMilestone: CalendarMilestone
        ): MilestoneFixed => {
          const startDate = dayjs(apiMilestone.startDate);

          const milestone: MilestoneFixed = {
            id: apiMilestone.id,
            milestoneCalendarKeyId: apiMilestone.milestoneCalendarKeyId,
            name: apiMilestone.name,
            text: apiMilestone.text,
            startDate: startDate.format("MMM 'YY"),
            startYear: startDate.year(),
            startMonth: startDate.month() + 1,
            hideDate: apiMilestone.hideDate,
            tooltip: apiMilestone.tooltip || null,
            status: apiMilestone.status || undefined
          };

          return milestone;
        };

        const mapToRangeMilestone = (
          apiMilestone: CalendarMilestone
        ): MilestoneRange => {
          const startDate = dayjs(apiMilestone.startDate);
          const endDate = dayjs(apiMilestone.endDate);

          const min = columns.years[0];

          let startYear = startDate.year();
          let startMonth = startDate.month() + 1;

          const endYear = endDate.year();
          const endMonth = endDate.month() + 1;

          // correct min range
          if (
            startYear < min.year ||
            (startYear === min.year && startMonth < min.quarters[0].months[0])
          ) {
            startYear = min.year;
            startMonth = min.quarters[0].months[0];
          }

          // note: max range handled fine by CSS even when incorrect column name

          const milestone: MilestoneRange = {
            id: apiMilestone.id,
            milestoneCalendarKeyId: apiMilestone.milestoneCalendarKeyId,
            name: apiMilestone.name,
            text: apiMilestone.text,
            startDate: startDate.format("MMM 'YY"),
            startYear: startYear,
            startMonth: startMonth,
            endDate: endDate.format("MMM 'YY"),
            endYear: endYear,
            endMonth: endMonth,
            hideDate: apiMilestone.hideDate,
            tooltip: apiMilestone.tooltip || null
          };

          return milestone;
        };

        // map milestones to calendar row
        for (const apiMilestone of apiRow.milestones) {
          if (!apiMilestone.endDate) {
            // fixed
            rowItem.fixedMilestones.push(mapToFixedMilestone(apiMilestone));
          } else {
            // range
            rowItem.rangeMilestones.push(mapToRangeMilestone(apiMilestone));
          }
        }
      }
    }
    setCalendarRows(rows);
  }, [data, columns]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={namespace}
      style={{ overflowX: !calendarRows.length ? "hidden" : undefined }} // disable "big-range-given" x-scrolling when fixed-width "no data" box shows
    >
      <KeyEventsCalendarHeader columns={columns} />
      {calendarRows.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={namespace}>
            {(provided, snapshot) => {
              const classNameBody = classnames(`${namespace}-droppable`, {
                isDraggingOver: snapshot.isDraggingOver
              });
              return (
                <div
                  className={classNameBody}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {calendarRows.map((r, i) => (
                    <KeyEventsCalendarRow
                      key={r.id}
                      item={r}
                      columns={columns}
                      index={i}
                      isDraggable={calendarMode.isRearrangeMode}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        <KeyEventsCalendarEmptyContainer filters={props.filters} />
      )}
    </div>
  );
}
