import React, { useMemo } from "react";
import "./TalentDinbModalLaborProductivityRevenuePerLnb.scss";
import TooltipContent from "common-components/tooltip-content/TooltipContent";
import StatusIcon from "common-components/status-icon/StatusIcon";
import { colors, format } from "utils";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { Text, BarChart } from "common-components";
import { TalentDinbLaborProductivityRevenuePerLnb } from "api/talent-dinb/talent-dinb-labor-productivity";
import { InactiveSection } from "api/common";
const namespace = "rts-pa-talent-dinb-modal-labor-productivity-revenue-per-lnb";

type Props = {
  data: TalentDinbLaborProductivityRevenuePerLnb | InactiveSection | undefined | null;
};

const getTooltipContent = (forecastYear: string) => {
  return (
    <TooltipContent>
      <div className={`${namespace}-tooltip`}>
        <Text className="tooltip-header">
          Percentage difference between {forecastYear} Latest Forecast and{" "}
          {forecastYear} Plan:
        </Text>
        <div className="status-items">
          <div className="status-line-item">
            <StatusIcon status="green" />
            <Text>0% and above</Text>
          </div>
          <div className="status-line-item">
            <StatusIcon status="yellow" />
            <Text>Between 0% and -0.5% or equal to -0.5%</Text>
          </div>
          <div className="status-line-item">
            <StatusIcon status="red" />
            <Text>Less than -0.5%</Text>
          </div>
          <div className="status-line-item">
            <StatusIcon status="gray" />
            <Text>Forecast Not Available</Text>
          </div>
        </div>
      </div>
    </TooltipContent>
  );
};

const TalentDinbModalLaborProductivityRevenuePerLnb = (props: Props) => {
  const title = "Revenue per Labor & Benefit Dollar";
  const chartData = useMemo(() => {
    if (!props.data || props.data.status === "inactive" || !props.data?.chart.length) {
      return [];
    }

    const arr = [];
    for (let i = 0; i < props.data.chart.length; i++) {
      const item = props.data.chart[i];

      arr.push({
        label: item.label,
        actuals: item.actuals === null ? undefined : item.actuals,
        forecast: item.forecast === null ? undefined : item.forecast,
        plan: item.plan === null ? undefined : item.plan
      });
    }

    return arr;
  }, [props.data]);

  if (!props.data)  {
    return <></>;
  } else if (props.data.status === "inactive") {
    return <AccordionWithStatus title={props.data.title} status="gray" />;
  } else if (!chartData.length) {
    return <></>;
  }

  const { forecastYear } = props.data;

  return (
    <AccordionWithStatus
      status={props.data?.status}
      title={title}
      className={namespace}
      accordionSyncKey="TalentModal"
      tooltipContent={getTooltipContent(forecastYear.toString())}
      links={props.data.links}
    >
      <BarChart
        showPopover={true}
        yAxisLabel="Revenue per Labor & Benefit $"
        data={chartData}
        dataKeys={[
          { barKey: "plan", color: colors.lightBlue },
          { barKey: "actuals", color: colors.primaryBlue },
          { barKey: "forecast", color: colors.teal }
        ]}
        yAxisTickFormatter={(
          value: string | number | Array<string | number>
        ) => {
          if (typeof value === "number") {
            return format.money(value, { decimals: 2 });
          }
          return value.toString();
        }}
      />
    </AccordionWithStatus>
  );
};

export default TalentDinbModalLaborProductivityRevenuePerLnb;
