import {
  Array,
  Boolean,
  Dictionary,
  Null,
  Number,
  Record,
  Static,
  String,
  Union
} from "runtypes";

const LineChartDataKeyValidator = Record({
  lineKey: String,
  color: String.nullable(),
  displayName: String.nullable(),
  dot: Boolean.nullable(),
  dash: Boolean.nullable(),
  hideInLegend: Boolean.nullable()
});

export type LineChartDataKey = Static<typeof LineChartDataKeyValidator>;

export const LineChartValidator = Record({
  data: Array(Dictionary(Union(Number, String, Null), String)),
  dataKeys: Array(LineChartDataKeyValidator),
  xKey: String
});

export type LineChart = Static<typeof LineChartValidator>;
