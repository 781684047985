import React from "react";
import "./KeyEventsCalendarRowMilestoneFixed.scss";
import { MilestoneFixed } from "components/key-events-calendar/calendarTypes";
import { MilestoneTypeIcon, Text } from "common-components";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";

export const namespace = "rts-pa-key-events-calendar-row-milestone-fixed";

type Props = {
  milestone: MilestoneFixed;
};

export default function KeyEventsCalendarRowMilestoneFixed(
  props: Props
): JSX.Element {
  const milestone = (
    <div
      id={`milestone_${props.milestone.id}`}
      className={namespace}
      style={{
        gridColumn: `y${props.milestone.startYear}m${props.milestone.startMonth} / span 6`
      }}
    >
      <MilestoneTypeIcon
        id={props.milestone.milestoneCalendarKeyId}
        size="large"
      />
      <div className={`${namespace}-container`}>
        <Text className={`${namespace}-text`}>{props.milestone.name}</Text>
        {!props.milestone.hideDate && (
          <Text
            className={`${namespace}-date${
              props.milestone.status === "subject to change"
                ? "-subject-to-change"
                : ""
            }`}
          >
            {props.milestone.startDate}
          </Text>
        )}
      </div>
    </div>
  );

  return (
    <>
      {props.milestone.tooltip ? (
        <AdvancedTooltip
          tooltip={props.milestone.tooltip}
          tooltipIcon={milestone}
          trigger="click"
        />
      ) : (
        milestone
      )}
    </>
  );
}
