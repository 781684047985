import { useContext, useEffect, useMemo, useRef } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "./permissions";

type Mode = "view" | "edit" | "rearrange";

type Response = {
  mode: Mode;
  prevMode: Mode;
  isViewMode: boolean;
  isEditMode: boolean;
  isRearrangeMode: boolean;
};

export function useGridModeHelper(): Response {
  const perms = useContext(PermissionsContext).grid;
  const location = useLocation();
  const navigate = useNavigate();
  const mode = useRef<Mode>("view");

  const result = useMemo<Response>(() => {
    const isRearrangeMode = !!matchRoutes(
      [{ path: pages.grid.edit.rearrange.path }],
      location
    );

    let isEditMode = false;
    if (!isRearrangeMode) {
      isEditMode = !!matchRoutes(
        [
          { path: pages.grid.edit.path },
          { path: pages.grid.edit.addItemModal.path },
          { path: pages.grid.edit.editItemModal.path },
          { path: pages.grid.edit.importItemModal.path }
        ],
        location
      );
    }

    let newMode: Mode = "view";
    if (isRearrangeMode) {
      newMode = "rearrange";
    } else if (isEditMode) {
      newMode = "edit";
    }

    const prevMode = mode.current;
    if (newMode !== prevMode) {
      mode.current = newMode;
    }

    return {
      mode: newMode,
      prevMode: prevMode,
      isViewMode: newMode === "view",
      isEditMode: isEditMode,
      isRearrangeMode: isRearrangeMode
    };
  }, [location]);

  //redirect to view-only if no permissions
  useEffect(() => {
    if (!result.isViewMode && !perms.edit) {
      navigate(pages.grid.go());
    }
  }, [result, perms, navigate]);

  return result;
}
