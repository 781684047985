import axios from "axios";
import {
  CalendarBoard,
  CalendarBoardValidator
} from "./calendar/calendar-board";
import { getKeyEventsId } from "utils/multitenancy";
import { Dayjs } from "dayjs";

export const getCalendarBoard = async (
  limitedView: boolean,
  start_date_min?: Dayjs,
  start_date_max?: Dayjs
): Promise<CalendarBoard> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CalendarBoard>(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/calendar_row/board`,
    {
      params: {
        limited_view: limitedView,
        start_date_min: start_date_min?.format("YYYY-MM-DD"),
        start_date_max: start_date_max?.format("YYYY-MM-DD")
      }
    }
  );

  CalendarBoardValidator.check(result.data);

  return result.data;
};

export const putCalendarBoard = async (
  rows: { id: number; order: number }[]
): Promise<CalendarBoard> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<CalendarBoard>(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/calendar_row/board/order`,
    { rows: rows }
  );

  CalendarBoardValidator.check(result.data);

  return result.data;
};
