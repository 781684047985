import React, { useMemo, useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import "./AgendaGridLineItem.scss";
import { AgendaItem } from "api/common";
import {
  deleteAgendaItem as deleteAgendaItemV2ApiCall,
  getAgendaItems
} from "api";
import { StatusIcon, Text } from "common-components";
import classnames from "classnames";
import {
  EditIcon,
  Tag
} from "@opsdti-global-component-library/amgen-design-system";
import DragIcon from "icons/drag-icon";
import { PermissionsContext } from "global/permissions";
import pages from "pages/pages";
import { useNavigate } from "react-router-dom";
import { useGridModeHelper } from "global/use-grid-mode-helper";
import TrashIcon from "icons/trash-icon";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStatusManager } from "global/use-status-manager";

export type Props = {
  agendaItem: AgendaItem;
  index: number;
};

const namespace = "rts-pa-agenda-grid-line-item";

const AgendaGridLineItem = (props: Props) => {
  const navigate = useNavigate();
  const gridPerms = useContext(PermissionsContext).grid;
  const gridMode = useGridModeHelper();

  const { refetch: refreshGrid } = useQuery(
    ["agenda-items", false],
    () => getAgendaItems(false),
    { enabled: false }
  );

  const { status: deleteStatus, mutate: deleteAgendaItem } = useMutation(
    deleteAgendaItemV2ApiCall,
    {
      onMutate: () => {
        overrideStatus("deleteAgendaItem", "loading");
      },
      onSuccess: () => {
        refreshGrid().then(() => {
          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("deleteAgendaItem", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("deleteAgendaItem", undefined);
      }
    }
  );

  const { overrideStatus } = useStatusManager(deleteStatus);

  const onItemClick = useMemo(() => {
    if (!gridMode.isViewMode) {
      return undefined;
    }

    let isClickable = false;

    switch (props.agendaItem.archetype) {
      case "commercial":
        isClickable = gridPerms.commercialModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.commercialModal.go(props.agendaItem.id));
          };
        }
        break;
      case "pipeline":
        isClickable = gridPerms.pipelineModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.pipelineModal.go(props.agendaItem.id));
          };
        }
        break;
      case "talent_dinb":
        isClickable = gridPerms.talentDinbModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.talentDinbModal.go(props.agendaItem.id));
          };
        }
        break;
      case "esg":
        isClickable = gridPerms.esgModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.esgModal.go(props.agendaItem.id));
          };
        }
        break;
      case "standalone":
        isClickable = gridPerms.standaloneModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.standaloneModal.go(props.agendaItem.id));
          };
        }
        break;
      case "biosimilars":
        isClickable = gridPerms.biosimilarsModal;
        if (isClickable) {
          return () => {
            navigate(pages.grid.biosimilarsModal.go(props.agendaItem.id));
          };
        }
        break;
    }

    return undefined;
  }, [
    gridMode,
    props.agendaItem,
    gridPerms.standaloneModal,
    gridPerms.commercialModal,
    gridPerms.pipelineModal,
    gridPerms.talentDinbModal,
    gridPerms.esgModal,
    gridPerms.biosimilarsModal,
    navigate
  ]);

  const containerClassName = classnames(`${namespace}-view-container`, {
    clickable: !!onItemClick
  });

  const statusNameContainer = (
    <div className={containerClassName} onClick={onItemClick}>
      <div className={`${namespace}-item-status`}>
        {/*NOTE JCG: "•" sometimes added through Home.scss when printing */}
        {props.agendaItem.status ? (
          <StatusIcon status={props.agendaItem.status} />
        ) : (
          <div>•</div>
        )}
      </div>
      <Text>{props.agendaItem.name}</Text>
    </div>
  );

  const tooltip = (
    <AdvancedTooltip
      tooltip={{
        sections: [
          {
            rows: [
              {
                label: "CEO Staff Prioritized Agenda Linked Item",
                content: []
              },
              {
                content: [
                  "This badge indicates that the imported item remains connected to its original imported item on the CEO Staff Prioritized Agenda, so any updates made to the original will automatically reflect here."
                ]
              }
            ]
          }
        ]
      }}
      tooltipIcon={
        <Tag
          text="CEO Staff"
          className={`${gridMode.isEditMode ? namespace + "-tag" : ""}`}
        />
      }
    />
  );

  const tagIconContainer = (
    <div className={`${namespace} with-tag-container`}>
      {props.agendaItem.isLinked && tooltip}

      {gridMode.isEditMode &&
        (props.agendaItem.isLinked ? (
          <div
            className={`${namespace}-trash`}
            onClick={() => {
              deleteAgendaItem(props.agendaItem.id);
            }}
          >
            <div className={`${namespace}-trash-icon-container`}>
              <TrashIcon height={12} width={12} />
            </div>
          </div>
        ) : (
          <div
            className={`${namespace}-pencil`}
            onClick={() => {
              navigate(pages.grid.edit.editItemModal.go(props.agendaItem.id));
            }}
          >
            <div className={`${namespace}-pencil-icon-container`}>
              <EditIcon height={12} width={12} />
            </div>
          </div>
        ))}
    </div>
  );

  if (gridMode.isViewMode || gridMode.isEditMode) {
    return (
      <div
        className={
          props.agendaItem.isLinked
            ? gridMode.isViewMode
              ? `${namespace} with-tag` // w/ CEO Staff Tag
              : `${namespace} with-tag-edit` // edit mode w/ CEO Staff Tag
            : namespace
        }
      >
        {statusNameContainer}
        {tagIconContainer}
      </div>
    );
  }

  //if gridMode.isRearrangeMode
  return (
    <Draggable
      draggableId={props.agendaItem.id.toString()}
      isDragDisabled={!gridMode.isRearrangeMode}
      index={props.index + 1}
      key={props.agendaItem.id}
    >
      {(provided, snapshot) => {
        const rootClassName = classnames(
          gridMode.isRearrangeMode && props.agendaItem.isLinked
            ? `${namespace} rearrange-tag`
            : namespace,
          {
            isDragging: snapshot.isDragging,
            isDraggable: gridMode.isRearrangeMode
          }
        );

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={rootClassName}
          >
            {statusNameContainer}
            <div className={`${namespace}-drag`} {...provided.dragHandleProps}>
              {props.agendaItem.isLinked && tooltip}
              <DragIcon height={10} />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default AgendaGridLineItem;
