import React, { useContext } from "react";
import "./KeyEventsCalendarHeader.scss";
import { Label } from "common-components";
import {
  SettingsCogIcon,
  theme
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";
import { Columns } from "../calendarTypes";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import KeyEventsCalendarPublishedDate from "../published-date/KeyEventsCalendarPublishedDate";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import dayjs from "dayjs";

const namespace = "rts-pa-key-events-calendar-header";

type Props = {
  columns: Columns;
};

export default function KeyEventsCalendarHeader(props: Props): JSX.Element {
  const perms = useContext(PermissionsContext);
  const navigate = useNavigate();
  const { token } = theme;

  const totalQuarters = props.columns.years.flatMap(y => y.quarters).length;
  const showMonths = 0 < totalQuarters && totalQuarters <= 4;

  const className = classNames(namespace, { "show-months": showMonths });

  return (
    <div className={className}>
      <div className={`${namespace}-left-container`}>
        <KeyEventsCalendarPublishedDate />
        <PermissionsWrapper permissions={perms.keyEvents.edit}>
          <SettingsCogIcon
            className={`${namespace}-icon`}
            height={14}
            onClick={() => navigate(pages.keyEvents.editCalendarModal.go())}
          />
        </PermissionsWrapper>
      </div>
      <div
        className={`${namespace}-right-container`}
        style={{
          gridTemplateColumns: props.columns.gridTemplateColumns,
          backgroundColor: token?.colorBorderSecondary // used during odd-pixel window sizings reveal white edge in last quarter's box
        }}
      >
        {props.columns.years.map((y, i) => {
          const className = classNames("year-container", {
            last: props.columns.years.length - 1 === i
          });

          const gridColumn = `y${y.year}m${y.quarters[0].months[0]} / y${
            y.year + 1
          }m1`;

          return (
            <div
              className={className}
              key={y.year}
              style={{
                gridColumn: gridColumn,
                backgroundColor: token?.colorPrimaryBg
              }}
            >
              <Label colorVariant="dark">{y.year}</Label>
            </div>
          );
        })}
        {props.columns.years.map((y, yi) => {
          return y.quarters.map((q, qi) => {
            const className = classNames("quarter-container", {
              last:
                props.columns.years.length - 1 === yi &&
                y.quarters.length - 1 === qi,
              "show-months": showMonths
            });

            return (
              <div
                key={`${y.year}-${q.num}`}
                className={className}
                style={{
                  backgroundColor: token?.colorBorderSecondary,
                  gridColumn: `y${y.year}m${q.months[0]} / span 3`
                }}
              >
                <Label colorVariant="dark">Q{q.num}</Label>
              </div>
            );
          });
        })}
        {showMonths &&
          props.columns.years.map((y, yi) => {
            return y.quarters.map((q, qi) => {
              return q.months.map((m, mi) => {
                const className = classNames("month-container", {
                  last:
                    props.columns.years.length - 1 === yi &&
                    y.quarters.length - 1 === qi &&
                    q.months.length - 1 === mi
                });

                const dayObj = dayjs();

                return (
                  <div
                    key={`${y.year}-${m}`}
                    className={className}
                    style={{
                      backgroundColor: token?.colorBorderSecondary,
                      gridColumn: `y${y.year}m${m} / span 1`
                    }}
                  >
                    <Label colorVariant="dark">
                      {dayObj.month(m - 1).format("MMM")}
                    </Label>
                  </div>
                );
              });
            });
          })}
      </div>
    </div>
  );
}
