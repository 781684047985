import React from "react";
import "./ComModalSupplyInventoryBelowTarget.scss";
import { CommercialSupplyInventoryBelowTargetSection } from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import StatusIcon from "common-components/status-icon/StatusIcon";
import { InactiveSection } from "api/common";
import { Label, Text } from "common-components";

const namespace = "rts-pa-commercial-modal-supply-inventory-below-target";

type LineItemProps = {
  label: string;
  text: string;
};
const InventoryBelowTargetLineItem = (props: LineItemProps) => {
  return (
    <div className={`${namespace}-metrics`}>
      <Label className={`${namespace}-metrics-label`}>{props.label}</Label>
      <Text className={`${namespace}-metrics-text`}>{props.text}</Text>
    </div>
  );
};

type Props = {
  data:
    | CommercialSupplyInventoryBelowTargetSection
    | InactiveSection
    | undefined
    | null;
};

const ComModalSupplyInventoryBelowTarget = (props: Props) => {
  const title = "Inventory Below Target";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return <AccordionWithStatus title={title} status="gray" />;
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      className={namespace}
      accordionSyncKey="CommercialModal"
      links={props.data.links}
    >
      <Text strong>SKUs Below Min. Target</Text>
      <div className={`${namespace}-metrics`}>
        <Text strong className={`${namespace}-metrics-label-fdp`}>
          FDP
        </Text>
        <div className={`${namespace}-metrics-status`}>
          <span className={`${namespace}-metrics-icon`}>
            <StatusIcon status={props.data.fdp.status} />
          </span>
          <Text strong>{props.data.fdp.value.toString()}</Text>
        </div>
      </div>
      <InventoryBelowTargetLineItem
        label="status"
        text={props.data.fdp.statusText}
      />
      <InventoryBelowTargetLineItem
        label="reason codes"
        text={props.data.fdp.reasonCodes.join(", ")}
      />
      <InventoryBelowTargetLineItem
        label="countries"
        text={props.data.fdp.countries.join(", ")}
      />
    </AccordionWithStatus>
  );
};

export default ComModalSupplyInventoryBelowTarget;
