import axios from "axios";
import { Array, Static, String } from "runtypes";

import { getAgendaId } from "utils/multitenancy";
import {
  AgendaItemValidator,
  LinkValidator,
  OwnersValidator,
  StatusTypeValidator,
  SummaryInfoValidator
} from "./common";

export const AgendaItemWithDetailsValidator = AgendaItemValidator.omit(
  "status"
).extend({
  archetypeLinkId: String.nullable(),
  statusType: StatusTypeValidator,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  links: Array(LinkValidator)
});

export type AgendaItemWithDetails = Static<
  typeof AgendaItemWithDetailsValidator
>;

export const getAgendaItem = async (
  id: number
): Promise<AgendaItemWithDetails> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<AgendaItemWithDetails>(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/agenda_item/${id}`
  );

  AgendaItemWithDetailsValidator.check(result.data);

  return result.data;
};

export const postAgendaItem = async (
  agendaItem: AgendaItemWithDetails
): Promise<AgendaItemWithDetails> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<AgendaItemWithDetails>(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/agenda_item`,
    agendaItem
  );

  AgendaItemWithDetailsValidator.check(result.data);

  return result.data;
};

export const putAgendaItem = async (
  agendaItem: AgendaItemWithDetails
): Promise<AgendaItemWithDetails> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<AgendaItemWithDetails>(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/agenda_item/${agendaItem.id}`,
    agendaItem
  );

  AgendaItemWithDetailsValidator.check(result.data);

  return result.data;
};

export const deleteAgendaItem = async (agendaItemId: number) => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.delete(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/agenda_item/${agendaItemId}`
  );

  return result;
};
