import React from "react";
import classNames from "classnames";
import { BarChart as DSBarChart } from "@opsdti-global-component-library/amgen-design-system";

const namespace = "rts-pa-barchart";

type BarChartData = {
  label: string;
  [key: string]: number | string | undefined;
};

type BarChartDataKey = {
  barKey: string;
  color: string;
};

type Props = {
  className?: string;
  data: BarChartData[];
  dataKeys: BarChartDataKey[];
  yAxisLabel: string;
  yAxisTickFormatter?: ((value: number | string) => string) | undefined;
  showPopover: boolean;
};

export const BarChart = (props: Props) => {
  const className = classNames(namespace, props.className);

  return (
    <div className={className}>
      <DSBarChart
        showPopover={props.showPopover}
        chartHeight={213}
        yAxisLabel={props.yAxisLabel}
        showLegend={true}
        data={props.data}
        dataKeys={props.dataKeys}
        xKey={"label"}
        yAxisTickFormatter={props.yAxisTickFormatter}
      />
    </div>
  );
};

export default BarChart;
