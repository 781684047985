import React from "react";
import "./KeyEventsCalendarRowMenuItem.scss";
import { Text } from "common-components";

export const namespace = "rts-pa-key-events-calendar-row-menu-item";

type Props = {
  icon?: JSX.Element;
  label: string;
  onClick: () => void;
};

export default function KeyEventsCalendarRowMenuItem(
  props: Props
): JSX.Element {
  return (
    <div className={namespace} onClick={props.onClick}>
      {props.icon}
      <Text>{props.label}</Text>
    </div>
  );
}
